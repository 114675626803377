.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body{
  font-size: 14px !important;
background: #F8F9FB !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login_background {
  /* background-image: url(./assets/login.png);
  background-size: cover; */
  background-color: #2562AE;
}
.c-white {
  color: white;
}
.fw-700 {
  font-weight: 700;
}
.wrapper {
  position: relative;
}

.resend_line {
  float: right;
  font-size: 10px;
}
.input {
  height: 50px;
  box-sizing: border-box;
  padding-left: 1.5rem;
}
.form-input-custom .input-group-text {
  background: white;
  border-right: none;
}
.form-input-custom input {
  border-left: none;
  font-size: 12px;
}
.form-input-custom .form-control:focus {
  box-shadow: none;
  border-color: #dee2e6;
}
.user_circle {
  background: #dfdada;
  padding: 10px;
  border-radius: 50%;
  margin: 3px 10px;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: x-large;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details{
  display: table;
  width: 100%;
}
.user-details{
display: table-cell;
width: 20%;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.primary-color {
  color: #ff4436 !important;
}
.status-card {
  width: 70%;
  margin: auto;
}
.s-cards {
  padding: 10px;
}
.interview-container{
  width: 60%;
}
.c-blue{
  color: #004FB7;
}
.icon_block {
  font-size: 30px;
  color: #1d0096;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px 0;
}
.count_block{
font-size: 20px;  
  padding: 10px 0;
}
.active-custom-nav a{
  color:#FF4436;
}
.user_name a{
  color: #000 !important;
}
.user_name a:hover{
  color: #FF4436 !important;
}
.active-custom-nav a:hover{
  color:#FF4436;
}
.active-custom-nav a:focus{
  color:#FF4436;
}
.rp-primary{
  background: #2562AE;
  top: 335px;
  left: 936px;
  padding: 4px 15px;
  border-radius: 4px;
  gap: 7px;
  color: white;
  border: none;  
  margin: 0 4px;
}
.rp-secondary{
  background: #999999;
  top: 335px;
  left: 936px;
  padding: 4px 15px;
  border-radius: 4px;
  gap: 7px;
  color: white;
  border: none;  
}
.text-right{
  text-align: right;
}
.filter-select{
  margin: 0 10px;
  padding: 2px 15px;
  border-color: #2562AE;
  color: #2562AE;
  border-radius: 4px;
}
.nav-shadow{
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.candidate_details input{
  width: 91%;
  padding: 8px;
  border: 1px solid #B8B8B8;
}
.candidate_details select{
  width: 91%;
  padding: 8px;
  border: 1px solid #B8B8B8;
}
.candidate_details textarea{
  width: 95%;
  border: 1px solid #B8B8B8;
  padding: 8px;
}
#fileInput{
  display: none;
}
#icon{
  width: 100px;
  cursor: pointer;
}
.file_upload{
  background-color: #E0E0E0;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 25px;
  border-radius: 4px;
}
.selected-card{
  background: aliceblue;
  border-radius: 4px;
}
.jd-changes:focus-visible{
  outline: none;
}
.pad-right-5{
  padding-right: 5px;
}
.status-online{
  color: #1BC100;
}
.status-offline{
  color: #FF4436;
}
.br-2{
  border-radius: 2px !important;
  box-shadow: 0px 0px 4px 0px #00000040;
  margin: 10px 0;
}
.due-in{
  color: #3939CD;
}
.table-header th{
  color: #858585 !important;
  font-weight: 400;
}
table th,td{
  padding: 1.5rem 0.5rem !important;
}
.cursor-pointer{
  cursor: pointer;
}
.successCard{
  position: absolute;
    z-index: 999;
    backdrop-filter: brightness(0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100vh;
    width: 100vw;
}
.quick-action .dropdown-toggle::after{
  display: none;
}
.stepper.cr {
  font-size: 14px !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
  list-style: none;
  font-family: sans-serif;
}
.stepper.cr li {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding: 0px 10px;
  position: relative;
  
}
.stepper.cr li div {
  position: relative;
  padding: 1px 0px;
  box-sizing: border-box;
  transform: scale(0.999999);
  transition: all 0.3s ease;
  cursor: pointer;
  background: #ddd;
}
.stepper.cr li div span {
  display: block;
  transition: all 0.3s ease;
}
.stepper.cr li div span:nth-child(1) {
  position: absolute;
  left: 100%;
  top: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #cccccc;
}
.stepper.cr li div span:nth-child(2) {
  position: absolute;
  right: 100%;
  top: 1px;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #cccccc;
  margin-top: -1px;
}
.stepper.cr li div span:nth-child(3) {
  position: absolute;
  right: 100%;
  bottom: 0px;
  border-top: 20px solid transparent;
  border-right: 20px solid #cccccc;
}
.stepper.cr li div span:nth-child(4) {
  position: absolute;
    right: 10px;
    top: 50%;
    height: 12px;
    width: 12px;
    margin-top: -6px;
    border-radius: 100%;
    background: transparent;
    z-index: 2;
    border: 1px solid #cccccc;
}
.stepper.cr li div span:nth-child(5) {
  position: absolute;
  left: 100%;
  top: 1px;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-left: 19px solid;

}
.stepper.cr li div span:nth-child(6) {
  position: absolute;
  right: 100%;
  top: 0px;
  border-bottom: 19px solid transparent;
  border-right: 19px solid;
  margin-right: -1px;
}
.stepper.cr li div span:nth-child(7) {
  position: absolute;
  right: 100%;
  bottom: 0px;
  border-top: 19px solid transparent;
  border-right: 19px solid;
  margin-right: -1px;
  margin-top: 0px;

}
.stepper.cr li div span:nth-child(8) {
  display: block;
  padding: 0px 20px;
  position: relative;
  height: 38px;
  line-height: 38px;
  background:white ;
  color: black;
}
.stepper.cr li div {
  background: #ddd;
}
.stepper.cr li div span:nth-child(1) {
  border-left-color: #ddd;
}
.stepper.cr li div span:nth-child(2), .stepper.cr li div span:nth-child(3) {
  border-right-color: #fff;
}
.stepper.cr li div span:nth-child(4) {
  background: transparent;
  border-color: #ddd;
}
.stepper.cr li div span:nth-child(5) {
  border-left-color: #ddd;
}
.stepper.cr li div span:nth-child(6), .stepper.cr li div span:nth-child(7) {
  border-right-color: #ddd;
}
.stepper.cr li div span:nth-child(8) {
  background: #ddd;
  color:black ;
}
.stepper.cr li.active div {
  background: #0747A6;
}
.stepper.cr li.active div span:nth-child(1) {
  border-left-color: #0747A6;
}
.stepper.cr li.active div span:nth-child(2){ 
  border-right-color: #0747A6;
}
.stepper.cr li.active div span:nth-child(3) {
  border-right-color: #0747A6;
}

.stepper.cr li.active div span:nth-child(4) {
  background: transparent;
  border-color: #0747A6;
}

.stepper.cr li.active.rejected-step div span:nth-child(4) {
  background: transparent;
  border-color: #ca2216;
}

.stepper.cr li.active div span:nth-child(5) {
  border-left-color: #0747A6;
}
.stepper.cr li.active div span:nth-child(6), .stepper.cr li.active div span:nth-child(7) {
  border-right-color: #0747A6;
}
.stepper.cr li.active div span:nth-child(8) {
  background: #0747A6;
  color: white;
}
.stepper.cr li.active.rejected-step div span:nth-child(8) {
  background: #ca2216;;
  color: white;
}
.stepper.cr li.completed div {
  background: #DEEBFF;
}
.stepper.cr li.completed div span:nth-child(1) {
  border-left-color: #DEEBFF;
  margin-left: -10px;
}
.stepper.cr li.completed div span:nth-child(2), .stepper.cr li.completed div span:nth-child(3) {
  border-right-color: #DEEBFF;
  margin-left: -10px;
}
.stepper.cr li.completed div span:nth-child(4) {
  border-color: #DEEBFF;
}
.stepper.cr li.completed div span:nth-child(5) {
  border-left-color: #DEEBFF;
}
.stepper.cr li.completed div span:nth-child(6), .stepper.cr li.completed div span:nth-child(7) {
  border-right-color: #DEEBFF;
  margin-left: -10px;
}
.stepper.cr li.completed div span:nth-child(8) {
  background: #DEEBFF;
  color: #0747A6;
}
.stepper.cr li:first-child {
  padding-left: 0px;
}
.stepper.cr li:first-child div {
  padding-left: 1px;
}
.stepper.cr li:first-child div span:nth-child(2), .stepper.cr li:first-child div span:nth-child(3), .stepper.cr li:first-child div span:nth-child(6), .stepper.cr li:first-child div span:nth-child(7) {
  display: none;
}

/* jobdescription textarea */
.ck-editor__editable{
  min-height: 200px;
}

/* userprofile */
.profile_details input{
  width: 80%;
  padding: 8px;
  border: 1px solid #B8B8B8;
  border-radius: 6px;
}
.profile_details select{
  width: 80%;
  padding: 8px;
  border: 1px solid #B8B8B8;
  border-radius: 6px;
}
.skill-box{
  padding: 2px 10px;
  border: rgb(121, 121, 121) 1px solid;
  border-radius: 25px;
}
.text-center a{
  text-decoration: none;
  color: black;
}
.profile_img{
  padding: 70px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-image: url('https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png');
  background-size: cover;
  background-position: center;
}
.attachments{
  border: #ebebeb 1px solid;
  margin: 6px 0px;
  padding: 5px;
  border-radius: 4px;
}
.attachments p{
  font-size: 15px;
  font-weight: 600;
}
.newcheck{
  height: 40px;
}
.download_img{
  height: 25px;
  color: #9e9e9e;
}
.user_profile {
  background: #dfdada;
  padding: 10px;
  border-radius: 50%;
  margin: 3px 10px;
  height: 25px;
  width: 25px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-left{
  border-left: 1px solid #c2c2c2;
}
.warning_box{
  background-color: rgba(255, 0, 0, 0.2);
  border: red 1px solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
}
.bg-blue{
  background:#2562AE;
}
.interview-comments{
border: 1px solid #ccc;
border-radius: 4px;
margin-bottom: 1em;
}
.comments-title{
padding: 10px;
color: white;
font-weight: 500;
}
.user-comment{
  padding: 15px 5px;

}
.align_right{
  justify-content: flex-end;
  align-items: center;
}
.user_circle_comments {
  background: #dfdada;
  padding: 10px;
  border-radius: 50%;
  margin: 3px 10px;
  height: 25px;
  width: 25px;
  text-align: center;
  font-size: medium;
  display: flex;
  justify-content: center;
  align-items: center;
}
.comment-text{
position: relative;

  margin-left: 2em;
  padding: 10px;
  background: #EEF5FE;
  border-radius: 4px;
  font-size: 13px;
}
.comment-reply{
  position: relative;
  right: 0;
    margin-left: 2em;
    padding: 10px;
    background: #EEF5FE;
    border-radius: 4px;
    font-size: 13px;
  }
.chat-block{
  position: relative;
  height: 300px;
  overflow: auto;
 
}
.new-comment{
  position: relative;
  border-top: 1px solid #ccc;
  background: white;
  border-radius: 4px;
  padding: 8px;

}
.new-comment-text input{
  width: 90%;
  border: none;
}
.new-comment-text input:focus{
  outline: none;
}
.dropdown-menu.show{
  position: fixed !important;
  z-index: 9999 !important;
  transform: translate3d(-67px, 33px, 0px) !important;
  inset: 0px 0px auto auto !important;
} 
.dropdown-menu.show .dropdown-item{
  font-size: 12px;
  height: 29px;
  align-items: center;
  display: flex;
}
.ag-center-cols-container{
  height: 50px !important;
}
.ag-row-position-absolute{
  height: 50px !important;
}
.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(1){
  z-index: 99 !important;
  
}
.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(1) .dropdown-menu.show{
  transform: translate3d(-67px, 0px, 0px) !important;
  inset: auto 0px 0px auto !important;
}
.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(2){
  z-index: 98 !important;
}

.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(2) .dropdown-menu.show{
  transform: translate3d(-67px, 0px, 0px) !important;
  inset: auto 0px 0px auto !important;
}
.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(3){
  /* z-index: 97 !important; */
}
.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(3) .dropdown-menu.show{
  transform: translate3d(-67px, 15px, 0px) !important;
  inset: auto 0px 0px auto !important;
}
.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(4){
  /* z-index: 96 !important; */
}
.ag-center-cols-viewport .ag-row-position-absolute:nth-last-child(4) .dropdown-menu.show{
  transform: translate3d(-67px, 35px, 0px) !important;
  inset: auto 0px 0px auto !important;
}
